import React from "react";

import { CampaignStatistics } from "../types/types";
import { Flex, Label, SelectOption } from "Atoms";
import { useLanguage } from "Providers";
import { useQoplaStore } from "Stores";
import { formatFinancialNumbers } from "PriceUtils";

type Props = {
    statistcs: CampaignStatistics;
    shop: SelectOption;
};

export const CampaignShopStatisticTile: React.FC<Props> = ({ statistcs, shop }) => {
    const { translate } = useLanguage();
    const { companyLocale } = useQoplaStore();

    const { numberRedeemedOrders, totalEarnings } = statistcs;

    return (
        <Flex
            direction="column"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.400"
            borderRadius="lg"
            height="max-content"
            width="100%"
            mt={4}
        >
            <Flex
                direction="column"
                backgroundColor="secondaryAlpha.100"
                width="100%"
                p={2}
                justifyContent="space-between"
            >
                <Flex alignItems="center" justifyContent="center">
                    <Label
                        size="lg"
                        backgroundColor="newPrimary"
                        fontWeight="bold"
                        color="white"
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {shop?.label || ""}
                    </Label>
                </Flex>
                <Flex width="100%" pt={2} justifyContent="space-between">
                    <Label size="lg" background="none" fontWeight="bold">
                        {translate("discountHasBeenUsed")}
                    </Label>
                    <Label size="lg" background="none" fontWeight="bold">
                        {numberRedeemedOrders}
                    </Label>
                </Flex>
                <Flex width="100%" justifyContent="space-between">
                    <Label size="lg" background="none" fontWeight="bold">
                        {translate("totalEarnings")}
                    </Label>
                    <Label size="lg" background="none" fontWeight="bold">
                        {formatFinancialNumbers(totalEarnings!, companyLocale)}
                    </Label>
                </Flex>
            </Flex>
        </Flex>
    );
};
