import React from "react";
import ReactJson from "react-json-view";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { modalNames } from "Constants";
import { useLanguage } from "Providers/languageProvider";
import { useMothershipLazyQuery, useMothershipQuery } from "Hooks";
import { GET_DISCOUNT_BY_CAMPAIGN_ID, GET_USER_TRACKER_BY_CAMPAIGN_ID_AND_EMAIL } from "GraphQLQueries";
import { CAMPAIGN_STATUS, Campaign, CampaignQuery } from "../types/types";
import { Flex, RHFormInput, Button } from "Atoms";

type ModalProps = {
    modalContent: {
        campaign: Campaign;
    };
    closeModal: (modal: string) => void;
};

export const CampaignInformationModal: React.FC<ModalProps> = ({ modalContent, closeModal }) => {
    const { translate } = useLanguage();

    const { campaign } = modalContent;

    const campaignIsLive = campaign.status === CAMPAIGN_STATUS.LIVE;

    /** Get Campaing discount */
    const { data, loading } = useMothershipQuery<CampaignQuery.getDiscountAndUserTracker>(GET_DISCOUNT_BY_CAMPAIGN_ID, {
        variables: { campaignId: campaign.id },
        fetchPolicy: "network-only"
    });

    const [getUserTrackerInformation, { data: userTrackerInfo, loading: loadingTrackerInfo }] =
        useMothershipLazyQuery<CampaignQuery.getDiscountAndUserTracker>(GET_USER_TRACKER_BY_CAMPAIGN_ID_AND_EMAIL);

    const {
        control,
        formState: { isSubmitting, isValid },
        handleSubmit
    } = useForm<{ email: string }>({
        mode: "onBlur",
        resolver: yupResolver(yup.object({ email: yup.string().email().required() }))
    });

    const onSubmit: SubmitHandler<{ email: string }> = async (data: { email: string }) => {
        getUserTrackerInformation({
            variables: {
                campaignId: campaign.id,
                email: data.email.toLocaleLowerCase()
            }
        });
    };

    const onHandleCloseModal = () => {
        closeModal(modalNames.CAMPAIGN_INFO_MODAL);
    };

    const discountData = data?.getDiscountByCampaignId;
    const userTrackerData = userTrackerInfo?.getUserCampaignTrackerByCampaignIdAndEmail;

    return (
        <Modal open placement="center" isScrolling size="xxl" height="80vh" onClose={onHandleCloseModal}>
            <ModalHeader display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                {`${translate("campaign")} - ${campaign.name} `}
            </ModalHeader>
            <ModalCloseBtn onClick={onHandleCloseModal} />
            <ModalBody>
                {campaignIsLive ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Flex width="100%" mb={8} minH={"70px"}>
                            <RHFormInput
                                name="email"
                                wrapperProps={{ width: "100%", m: 0, p: 0, mr: 2 }}
                                control={control}
                                formLabel={translate("emailAlt")}
                                isFullWidth
                                border={"1px solid"}
                                borderColor={"gray.400"}
                            />
                            <Button
                                type="submit"
                                themeColor="blue"
                                isLoading={loadingTrackerInfo || isSubmitting}
                                disabled={!isValid || isSubmitting}
                                mt={10}
                                height={"42px"}
                                fullWidth
                            >
                                {translate("search")}
                            </Button>
                        </Flex>
                    </form>
                ) : null}

                <Flex height="auto" width={"100%"} px={6} py={4} backgroundColor={"blue.50"} direction={"column"}>
                    {campaignIsLive && !!discountData ? (
                        <>
                            {!!userTrackerData && (
                                <ReactJson
                                    displayDataTypes={false}
                                    name={translate("user")}
                                    src={JSON.parse(JSON.stringify(userTrackerData))}
                                    collapsed={1}
                                />
                            )}
                            <ReactJson
                                displayDataTypes={false}
                                name={translate("discount")}
                                src={JSON.parse(JSON.stringify(discountData))}
                                collapsed={1}
                            />
                        </>
                    ) : null}
                    <ReactJson
                        displayDataTypes={false}
                        name={translate("campaign")}
                        src={JSON.parse(JSON.stringify(campaign))}
                        collapsed={1}
                    />
                    {!campaignIsLive && !loading ? (
                        <ReactJson
                            displayDataTypes={false}
                            name={translate("discount")}
                            src={JSON.parse(JSON.stringify(discountData))}
                            collapsed={1}
                        />
                    ) : null}
                </Flex>
            </ModalBody>
            <ModalActions></ModalActions>
        </Modal>
    );
};
