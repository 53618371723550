import React from "react";

import { Center, Header, Image, Stack } from "Atoms";
import {
    Modal,
    ModalBody,
    ModalCloseBtn,
    ModalHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from "Molecules";
import { IModalContext, modals } from "Providers";
import { Nets } from "Assets";

type Props = IModalContext<{}>;

export const CompanyUserBenefitsNetsPricesModal: React.FC<Props> = ({ closeModal }) => {
    return (
        <Modal open isScrolling onClose={() => closeModal(modals.companyUserBenefitsNetsPricesModal)}>
            <ModalHeader>
                Nets prislista
                <ModalCloseBtn />
            </ModalHeader>
            <ModalBody>
                <Stack stretch={12}>
                    <Center>
                        <Image src={Nets} rounded="full" maxW={40} />
                    </Center>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeader>Qopla Partner kortinlösen</TableHeader>
                                <TableHeader />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow textDecoration="underline">
                                <TableCell>Fast transaktionsavgift</TableCell>
                                <TableCell>0.29kr</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Debit Visa</TableCell>
                                <TableCell>0.29%</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Credit Visa</TableCell>
                                <TableCell>0.49%</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Debit Mastercard</TableCell>
                                <TableCell>0.29%</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Credit Mastercard</TableCell>
                                <TableCell>0.49%</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Utanför EU</TableCell>
                                <TableCell>1.20%</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Företagskort</TableCell>
                                <TableCell>1.20%</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>CNP Charge</TableCell>
                                <TableCell>0.30%</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Header size="md" textAlign="center">
                        Kontaka din säljare för mer info
                    </Header>
                </Stack>
            </ModalBody>
        </Modal>
    );
};
