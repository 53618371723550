import { SelectOption } from "Atoms";
import { Discount } from "Types";

// Types
export type Campaign = {
    id?: string;
    name: string;
    companyId: string;
    shopIds: string[];
    campaignType: CAMPAIGN_TYPE;
    discountType: CAMPAIGN_DISCOUNT_TYPE;
    campaignCode?: string;
    discountRate: number;
    startDate: Date;
    endDate: Date;
    status?: CAMPAIGN_STATUS;
    theme: CAMPAIGN_THEME;
    pricingType: CAMPAIGN_PRICING_TYPE; //todo
    totalEarnedRevenue?: number;
    totalCost?: number;
    maxNumberOfUsersPerShop: number;
    numberOfMonthsBack: number;
    numberOfHistoricOrders: number;
    maxRedeemed: number;
    campaignUserSearch?: CampaignUserSearchDTO;
    statistics?: CampaignStatistics[];
    useWithoutEmail: boolean;
    emailOnlyDiscount?: boolean;
    costCapType?: CAMPAIGN_COST_CAP_TYPE;
    costCapAmount?: number;
    remainingEmailsToSend?: number;
    minCostAmount?: number;
};

export type CampaignStatistics = {
    shopId: string;
    totalCost: number;
    numberRedeemedOrders: number;
    numberTargetedUsers: number;
    totalEarnings: number;
};

export type CampaignUserSearchDTO = {
    companyId: string;
    shopIds: string[];
    campaignType: CAMPAIGN_TYPE;
    numberOfMonthsBack: number;
    numberOfHistoricOrders: number;
    fromDate: string;
    toDate: string;
    campaignStartDate: string;
    campaignEndDate: string;
    maxNumberOfUsersPerShop?: number;
    potentialUserCount?: UserCount[];
};

export type UserCount = {
    shopId: string;
    numberOfPotentialUsers: number;
    shopName?: string;
};

export type TotalUserCountAndCost = {
    numberOfPotentialUsers: number;
    numberOfShops: number;
    isCostPerShop: boolean;
    maxCostCap: number;
    totalCost: number;
    costPerUser: number;
};

export type Stepper = {
    step: number;
    title: string;
};

/** Landing Page - for display of current campaign status */
export type CampaignStatusDisplay = {
    [key in keyof typeof CAMPAIGN_STATUS]: { colour: string; translationKey: string };
};

/** Type for sending to Email Preview */
export type EmailPreview = {
    name?: string;
    discountRate?: number;
    discountCode?: string;
    shopName?: string;
};

export type UserCampaignTracker = {
    id: string;
    email: string;
    name: string;
    phoneNumber: string;
    userAccountId: string;
    companyId: string;
    shopId: string;
    campaignId: string;
    lockedUntil: string; 
    startDate: string; 
    discountCode: string;
    numberRedeemed: number;
    maxRedeemed: number;
    emailSent: boolean;
    status: CAMPAIGN_STATUS; 
};

type EmailPreviewNames = keyof EmailPreview;

export type SetEmailPreviewFunction = (prop: EmailPreviewNames, value: string | number) => void;

// const

export const ALL_SHOP_VALUE = "ALL_SHOP_VALUE";
export const COST_PER_EMAIL = 1;
export const MIN_CAMPAIGN_COST = 199;
export const EMAIL_BATCH_SEND_LIMIT = 2;
export const NEXT_CAMPAIGN_IN_DAYS = 30;

// Select options

export const maxRedeemedSelectOptions: SelectOption[] = [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "5", value: "5" },
    { label: "10", value: "10" }
];

export const discountRateSelectOptions: SelectOption[] = [
    { label: "15%", value: "15" },
    { label: "20%", value: "20" },
    { label: "25%", value: "25" },
    { label: "30%", value: "30" },
    { label: "40%", value: "40" },
    { label: "50%", value: "50" }
];

export const numberOfMonthsActiveSelectOptions: SelectOption[] = [
    { label: "36", value: "36" },
    { label: "24", value: "24" },
    { label: "18", value: "18" },
    { label: "12", value: "12" },
    { label: "6", value: "6" },
    { label: "3", value: "3" }
];

export const numberOfMonthInactiveSelectOptions: SelectOption[] = [
    { label: "12", value: "12" },
    { label: "6", value: "6" },
    { label: "3", value: "3" }
];

export const numberOfOrdersSelectOptions: SelectOption[] = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" }
];

// Apollo Types

export namespace CampaignQuery {
    export type getShopIds = {
        getAllShopsByCompanyIds: SelectOption[];
    };
    export type getValidDiscountCodes = {
        getValidDiscountCodes: string[];
    };
    export type getPotentialUsers = {
        potentialNumberOfEmailsForCampaign: CampaignUserSearchDTO;
    };
    export type getAllCampaigns = {
        getAllCampaignsByCompanyIdAndStatus: Campaign[];
    };
    export type getDiscountAndUserTracker = {
        getUserCampaignTrackerByCampaignIdAndEmail: UserCampaignTracker;
        getDiscountByCampaignId: Discount;
    }; 
}

export namespace CampaignMutation {
    export type saveCampaign = {
        upsertCampaign: {
            newCampaign: Partial<Campaign>;
            userSearchDTO: CampaignUserSearchDTO;
        };
    };
}

// Enumerations

export enum CAMPAIGN_TYPE {
    ACTIVE_CUSTOMERS = "ACTIVE_CUSTOMERS",
    INACTIVE_CUSTOMERS = "INACTIVE_CUSTOMERS"
} // Think to change this to just active and inactive!

export enum CAMPAIGN_STATUS {
    LIVE = "LIVE",
    ENDED = "ENDED",
    CANCELLED_BEFORE_END_DATE = "CANCELLED_BEFORE_END_DATE",
    PENDING = "PENDING"
}

/** FUTURE THING */
export enum CAMPAIGN_PRICING_TYPE {
    FIXED_PRICE = "FIXED_PRICE",
    VARIABLE_PRICE = "VARIABLE_PRICE"
}

export enum CAMPAIGN_THEME {
    SUMMER = "SUMMER",
    GENERAL = "GENERAL"
}

export enum CAMPAIGN_DISCOUNT_TYPE {
    GROUP = "GROUP",
    INDIVIDUAL = "INDIVIDUAL"
}

export enum DEFAULT_VALUES {
    ACTIVE_MONTHS_BACK = 36,
    INACTIVE_MONTHS_BACK = 3,
    NUMBER_HISTORIC_ORDERS = 1
}

export enum CAMPAIGN_COST_CAP_TYPE {
    PER_SHOP = "PER_SHOP",
    PER_COMPANY = "PER_COMPANY"
}

export enum MAX_COST_CAP {
    PER_SHOP = 3500,
    PER_COMPANY = 25000
}
