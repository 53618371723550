import React from "react";

import { useTimeInterval } from "Hooks";
import { Header, BaseBoxProps } from "Atoms";

const formatter = new Intl.DateTimeFormat("sv-SE", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
});

type Props = BaseBoxProps;

export const Clock: React.FC<Props> = ({ size, ...rest }) => {
    const time = useTimeInterval(() => formatter.format(new Date()));
    return (
        <Header size="xl" color="white" fontWeight="normal" m="0" {...rest}>
            {time}
        </Header>
    );
};
