import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import { Flex, Header, Text, Box } from "Atoms";
import "./NotFoundTextFlicker.scss";

type LocationState = {
    redirectReasons: any;
    from: string;
};

const NotFound: React.FC<{}> = ({  }) => {

    const location = useLocation();
    const state = location.state as LocationState;

    useEffect(() => {
        if (location.state) {
            const stringiedRedirectReasons = JSON.stringify(state.redirectReasons);
            Sentry.withScope(scope => {
                if (stringiedRedirectReasons !== undefined) {
                    scope.setExtra("FROM_URL", state.from);
                    scope.setExtra("REDIRECT_REASONS", stringiedRedirectReasons);
                    Sentry.captureMessage("Redirect to /notFound");
                }
            });
        }
    }, []);

    return (
        <Flex justify="center" align="center">
            <Box px={4}>
                <Header as="h1" size="4xl" color="newPrimary" fontFamily="qopla" textAlign="center">
                    <span className="letterFlicker">Q</span>
                    opla
                </Header>
                <Header as="h1" textAlign="center">
                    404 - Hittade inte sidan
                </Header>
                <Text textAlign="center">
                    Sidan du letar efter finns inte. Den har antingen blivit borttagen, bytt namn, eller är temporärt
                    otillgänglig.
                </Text>
            </Box>
        </Flex>
    );
};

export default NotFound;
