import React from "react";

import { IDrawerComponentContext, useQopla } from "Providers";
import { useMothershipQuery } from "Hooks";
import { Campaign, CampaignQuery, CampaignStatistics } from "../types/types";
import { GET_SHOP_IDS_BY_COMPANY_ID } from "GraphQLQueries";
import {
    CurrentPage,
    Drawer,
    DrawerActions,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    PageButtons,
    Pagination
} from "Organisms";
import { Flex, Skeleton } from "Atoms";
import { CampaignShopStatisticTile } from "./CampaignShopStatisticTile";

type Props = {
    campaign: Campaign;
};

export const CampaignStatisticDrawer: React.FC<IDrawerComponentContext<Props>> = ({
    props: { campaign },
    onCloseDrawer
}) => {
    const {
        selectedCompany: { id: companyId }
    } = useQopla();

    /** Get Shop Ids & names for campaign target */
    const { data, loading: isLoading } = useMothershipQuery<CampaignQuery.getShopIds>(GET_SHOP_IDS_BY_COMPANY_ID, {
        variables: { companyIds: [companyId] }
    });

    /** Select all stats that have targeted end users */
    const allStatistics = campaign?.statistics?.filter(stat => stat.numberTargetedUsers > 0) ?? [];
    const shopSelections = data?.getAllShopsByCompanyIds ?? [];

    return (
        <Drawer open onClose={onCloseDrawer}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader display="flex" justifyContent="center" alignItems="center">
                    {campaign.name}
                </DrawerHeader>
                <DrawerCloseButton top="15px" />
                <DrawerBody maxHeight={"100vh"}>
                    {isLoading && (
                        <Flex direction="column">
                            {[...Array(7)].map((_, index: number) => {
                                return <Skeleton width="100%" height="5rem" mt={2} key={index + 1} />;
                            })}
                        </Flex>
                    )}
                    {!!allStatistics.length && !!shopSelections.length && (
                        <>
                            <Pagination items={allStatistics} numberPerPage={4}>
                                <PageButtons showMaxPage={4} />
                                <CurrentPage
                                    width="100%"
                                    wrapperProps={{ justifyContent: "flex-start" }}
                                    renderItem={(stat: CampaignStatistics, _: number) => {
                                        const shopSelect = shopSelections.find(select => select.value === stat.shopId);
                                        return (
                                            <React.Fragment key={stat.shopId}>
                                                <CampaignShopStatisticTile shop={shopSelect!} statistcs={stat} />
                                            </React.Fragment>
                                        );
                                    }}
                                />
                            </Pagination>
                        </>
                    )}
                </DrawerBody>
                <DrawerActions></DrawerActions>
            </DrawerContent>
        </Drawer>
    );
};
