import React from "react";
import { NavigateFunction } from "react-router";

import { modalNames } from "Constants";
import { useLanguage, IModalContext } from "Providers";
import { Modal, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { Box } from "Atoms";
import { UmbrellaCompanyForm } from "../../super-admin/components/UmbrellaCompanyForm";
import { useMothershipMutation } from "Hooks";
import { UPSERT_UMBRELLA_ADMIN_USER } from "GraphQLMutations";

type ModalContentProps = {
    companyUserId: string;
    navigate: NavigateFunction;
    refetch: () => void;
};

export const CreateUmbrellaCompanyModal: React.FC<IModalContext<ModalContentProps>> = ({
    closeModal,
    modalContent
}) => {
    const { translate } = useLanguage();
    const [upsertUmbrellaAdminUser] = useMothershipMutation(UPSERT_UMBRELLA_ADMIN_USER);

    const { companyUserId, navigate, refetch } = modalContent;

    const handleCloseModal = async (umbrellaCompanyId: string) => {
        const { data } = await upsertUmbrellaAdminUser({
            variables: {
                companyUserId,
                umbrellaCompanyId
            }
        });
        if (data?.upsertUmbrellaAdminUser) {
            refetch();
        }

        closeModal(modalNames.CREATE_UMBRELLA_COMPANY_MODAL);
    };
    return (
        <Modal open={true} onClose={() => closeModal(modalNames.CREATE_UMBRELLA_COMPANY_MODAL)} size="xl" isScrolling>
            <ModalCloseBtn onClick={() => closeModal(modalNames.CREATE_UMBRELLA_COMPANY_MODAL)} />
            <ModalHeader>
                <Box ml={4}>{translate("createNewUmbrellaCompany")}</Box>
            </ModalHeader>
            <ModalBody overflow="auto">
                <UmbrellaCompanyForm navigate={navigate} handleCloseModal={handleCloseModal} />
            </ModalBody>
        </Modal>
    );
};
